/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkDeleteSectionPermissions } from '../fn/menu/bulk-delete-section-permissions';
import { BulkDeleteSectionPermissions$Params } from '../fn/menu/bulk-delete-section-permissions';
import { createMenuSection } from '../fn/menu/create-menu-section';
import { CreateMenuSection$Params } from '../fn/menu/create-menu-section';
import { createMenuSectionPermission } from '../fn/menu/create-menu-section-permission';
import { CreateMenuSectionPermission$Params } from '../fn/menu/create-menu-section-permission';
import { deleteMenuSection } from '../fn/menu/delete-menu-section';
import { DeleteMenuSection$Params } from '../fn/menu/delete-menu-section';
import { deleteMenuSectionPermissions } from '../fn/menu/delete-menu-section-permissions';
import { DeleteMenuSectionPermissions$Params } from '../fn/menu/delete-menu-section-permissions';
import { GetContentListResponse } from '../models/get-content-list-response';
import { getMenuSectionById } from '../fn/menu/get-menu-section-by-id';
import { GetMenuSectionById$Params } from '../fn/menu/get-menu-section-by-id';
import { getMenuSectionPermissions } from '../fn/menu/get-menu-section-permissions';
import { GetMenuSectionPermissions$Params } from '../fn/menu/get-menu-section-permissions';
import { getMenuSections } from '../fn/menu/get-menu-sections';
import { GetMenuSections$Params } from '../fn/menu/get-menu-sections';
import { GetMenuSectionsResponse } from '../models/get-menu-sections-response';
import { GetPermissionsResponse } from '../models/get-permissions-response';
import { getSectionContentList } from '../fn/menu/get-section-content-list';
import { GetSectionContentList$Params } from '../fn/menu/get-section-content-list';
import { getSectionContentListByCode } from '../fn/menu/get-section-content-list-by-code';
import { GetSectionContentListByCode$Params } from '../fn/menu/get-section-content-list-by-code';
import { MenuSection } from '../models/menu-section';
import { updateMenuSection } from '../fn/menu/update-menu-section';
import { UpdateMenuSection$Params } from '../fn/menu/update-menu-section';


/**
 * Služby pre navigačné menu
 */
@Injectable({ providedIn: 'root' })
export class MenuService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMenuSections()` */
  static readonly GetMenuSectionsPath = '/admin/menu';

  /**
   * Načítanie sekcií.
   *
   * Služba načíta zoznam sekcií pre navigačné menu
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMenuSections()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuSections$Response(params?: GetMenuSections$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMenuSectionsResponse>> {
    return getMenuSections(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie sekcií.
   *
   * Služba načíta zoznam sekcií pre navigačné menu
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMenuSections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuSections(params?: GetMenuSections$Params, context?: HttpContext): Observable<GetMenuSectionsResponse> {
    return this.getMenuSections$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMenuSectionsResponse>): GetMenuSectionsResponse => r.body)
    );
  }

  /** Path part for operation `createMenuSection()` */
  static readonly CreateMenuSectionPath = '/admin/menu';

  /**
   * Vytvorenie sekcie.
   *
   * Služba na vytvorenie sekcie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMenuSection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMenuSection$Response(params: CreateMenuSection$Params, context?: HttpContext): Observable<StrictHttpResponse<MenuSection>> {
    return createMenuSection(this.http, this.rootUrl, params, context);
  }

  /**
   * Vytvorenie sekcie.
   *
   * Služba na vytvorenie sekcie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMenuSection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMenuSection(params: CreateMenuSection$Params, context?: HttpContext): Observable<MenuSection> {
    return this.createMenuSection$Response(params, context).pipe(
      map((r: StrictHttpResponse<MenuSection>): MenuSection => r.body)
    );
  }

  /** Path part for operation `getMenuSectionById()` */
  static readonly GetMenuSectionByIdPath = '/admin/menu/{id}';

  /**
   * Načítanie sekcie.
   *
   * Načíta sekciu podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMenuSectionById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuSectionById$Response(params: GetMenuSectionById$Params, context?: HttpContext): Observable<StrictHttpResponse<MenuSection>> {
    return getMenuSectionById(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie sekcie.
   *
   * Načíta sekciu podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMenuSectionById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuSectionById(params: GetMenuSectionById$Params, context?: HttpContext): Observable<MenuSection> {
    return this.getMenuSectionById$Response(params, context).pipe(
      map((r: StrictHttpResponse<MenuSection>): MenuSection => r.body)
    );
  }

  /** Path part for operation `updateMenuSection()` */
  static readonly UpdateMenuSectionPath = '/admin/menu/{id}';

  /**
   * Aktualizácia sekcie.
   *
   * Služba aktualizuje danú sekciu podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMenuSection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMenuSection$Response(params: UpdateMenuSection$Params, context?: HttpContext): Observable<StrictHttpResponse<MenuSection>> {
    return updateMenuSection(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualizácia sekcie.
   *
   * Služba aktualizuje danú sekciu podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMenuSection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMenuSection(params: UpdateMenuSection$Params, context?: HttpContext): Observable<MenuSection> {
    return this.updateMenuSection$Response(params, context).pipe(
      map((r: StrictHttpResponse<MenuSection>): MenuSection => r.body)
    );
  }

  /** Path part for operation `deleteMenuSection()` */
  static readonly DeleteMenuSectionPath = '/admin/menu/{id}';

  /**
   * Zmazanie sekcie.
   *
   * Služba zmaže sekciu podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMenuSection()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMenuSection$Response(params: DeleteMenuSection$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteMenuSection(this.http, this.rootUrl, params, context);
  }

  /**
   * Zmazanie sekcie.
   *
   * Služba zmaže sekciu podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMenuSection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMenuSection(params: DeleteMenuSection$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteMenuSection$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getMenuSectionPermissions()` */
  static readonly GetMenuSectionPermissionsPath = '/admin/menu/{id}/permissions';

  /**
   * Načítanie oprávnení pre sekciu.
   *
   * Služba načíta zoznam oprávnení (produkty, spoločnosti) pre sekciu podľa id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMenuSectionPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuSectionPermissions$Response(params: GetMenuSectionPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetPermissionsResponse>> {
    return getMenuSectionPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie oprávnení pre sekciu.
   *
   * Služba načíta zoznam oprávnení (produkty, spoločnosti) pre sekciu podľa id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMenuSectionPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMenuSectionPermissions(params: GetMenuSectionPermissions$Params, context?: HttpContext): Observable<GetPermissionsResponse> {
    return this.getMenuSectionPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPermissionsResponse>): GetPermissionsResponse => r.body)
    );
  }

  /** Path part for operation `createMenuSectionPermission()` */
  static readonly CreateMenuSectionPermissionPath = '/admin/menu/{id}/permissions';

  /**
   * Pridanie oprávnenia.
   *
   * Služba pridá oprávnenie pre sekciu podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMenuSectionPermission()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMenuSectionPermission$Response(params: CreateMenuSectionPermission$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return createMenuSectionPermission(this.http, this.rootUrl, params, context);
  }

  /**
   * Pridanie oprávnenia.
   *
   * Služba pridá oprávnenie pre sekciu podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMenuSectionPermission$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMenuSectionPermission(params: CreateMenuSectionPermission$Params, context?: HttpContext): Observable<boolean> {
    return this.createMenuSectionPermission$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `bulkDeleteSectionPermissions()` */
  static readonly BulkDeleteSectionPermissionsPath = '/admin/menu/{id}/permissions';

  /**
   * Hromadné zmazanie oprávnení.
   *
   * Služba zmaže všetky oprávnenia pre sekciu podľa typu
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDeleteSectionPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDeleteSectionPermissions$Response(params: BulkDeleteSectionPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return bulkDeleteSectionPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Hromadné zmazanie oprávnení.
   *
   * Služba zmaže všetky oprávnenia pre sekciu podľa typu
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkDeleteSectionPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDeleteSectionPermissions(params: BulkDeleteSectionPermissions$Params, context?: HttpContext): Observable<boolean> {
    return this.bulkDeleteSectionPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `deleteMenuSectionPermissions()` */
  static readonly DeleteMenuSectionPermissionsPath = '/admin/menu/{id}/permissions/{permissionId}';

  /**
   * Načítanie oprávnení pre sekciu.
   *
   * Služba zmaže oprávnenie (produkty, spoločnosti) pre sekciu podľa id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMenuSectionPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMenuSectionPermissions$Response(params: DeleteMenuSectionPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteMenuSectionPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie oprávnení pre sekciu.
   *
   * Služba zmaže oprávnenie (produkty, spoločnosti) pre sekciu podľa id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMenuSectionPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMenuSectionPermissions(params: DeleteMenuSectionPermissions$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteMenuSectionPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getSectionContentList()` */
  static readonly GetSectionContentListPath = '/admin/menu/{id}/contentList';

  /**
   * Načítanie zoznamu stránok pre sekciu.
   *
   * Služba pre načítanie zoznamu stránok pre zvolenú sekciu podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSectionContentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSectionContentList$Response(params: GetSectionContentList$Params, context?: HttpContext): Observable<StrictHttpResponse<GetContentListResponse>> {
    return getSectionContentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie zoznamu stránok pre sekciu.
   *
   * Služba pre načítanie zoznamu stránok pre zvolenú sekciu podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSectionContentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSectionContentList(params: GetSectionContentList$Params, context?: HttpContext): Observable<GetContentListResponse> {
    return this.getSectionContentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetContentListResponse>): GetContentListResponse => r.body)
    );
  }

  /** Path part for operation `getSectionContentListByCode()` */
  static readonly GetSectionContentListByCodePath = '/admin/menuByCode/{code}/contentList';

  /**
   * Načítanie zoznamu stránok.
   *
   * Služba pre načítanie zoznamu stránok pre zvolenú sekciu podľa kodu
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSectionContentListByCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSectionContentListByCode$Response(params: GetSectionContentListByCode$Params, context?: HttpContext): Observable<StrictHttpResponse<GetContentListResponse>> {
    return getSectionContentListByCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie zoznamu stránok.
   *
   * Služba pre načítanie zoznamu stránok pre zvolenú sekciu podľa kodu
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSectionContentListByCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSectionContentListByCode(params: GetSectionContentListByCode$Params, context?: HttpContext): Observable<GetContentListResponse> {
    return this.getSectionContentListByCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetContentListResponse>): GetContentListResponse => r.body)
    );
  }

}
