/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContentTemplate } from '../models/content-template';
import { getContentTemplateByType } from '../fn/codelist/get-content-template-by-type';
import { GetContentTemplateByType$Params } from '../fn/codelist/get-content-template-by-type';
import { GetContentTemplateListResponse } from '../models/get-content-template-list-response';
import { getContentTemplates } from '../fn/codelist/get-content-templates';
import { GetContentTemplates$Params } from '../fn/codelist/get-content-templates';
import { getProducts } from '../fn/codelist/get-products';
import { GetProducts$Params } from '../fn/codelist/get-products';
import { GetProductsResponse } from '../models/get-products-response';


/**
 * Služby pre načítanie číselníkov a statických dát
 */
@Injectable({ providedIn: 'root' })
export class CodelistService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getContentTemplates()` */
  static readonly GetContentTemplatesPath = '/admin/contentTemplate';

  /**
   * Načítanie zoznamu šablón.
   *
   * Služba načíta zoznam šablón na vytvorenie novej stranky
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentTemplates$Response(params?: GetContentTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<GetContentTemplateListResponse>> {
    return getContentTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie zoznamu šablón.
   *
   * Služba načíta zoznam šablón na vytvorenie novej stranky
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentTemplates(params?: GetContentTemplates$Params, context?: HttpContext): Observable<GetContentTemplateListResponse> {
    return this.getContentTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetContentTemplateListResponse>): GetContentTemplateListResponse => r.body)
    );
  }

  /** Path part for operation `getContentTemplateByType()` */
  static readonly GetContentTemplateByTypePath = '/admin/contentTemplate/{templateType}';

  /**
   * Načítanie šablóny.
   *
   * Služba načíta šablónu podla typu
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentTemplateByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentTemplateByType$Response(params: GetContentTemplateByType$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentTemplate>> {
    return getContentTemplateByType(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie šablóny.
   *
   * Služba načíta šablónu podla typu
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentTemplateByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentTemplateByType(params: GetContentTemplateByType$Params, context?: HttpContext): Observable<ContentTemplate> {
    return this.getContentTemplateByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentTemplate>): ContentTemplate => r.body)
    );
  }

  /** Path part for operation `getProducts()` */
  static readonly GetProductsPath = '/admin/products';

  /**
   * Načítanie číselníka.
   *
   * Služba načíta číselník podľa typu
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProducts$Response(params?: GetProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<GetProductsResponse>> {
    return getProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie číselníka.
   *
   * Služba načíta číselník podľa typu
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProducts(params?: GetProducts$Params, context?: HttpContext): Observable<GetProductsResponse> {
    return this.getProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetProductsResponse>): GetProductsResponse => r.body)
    );
  }

}
